import React from 'react'
import { motion } from 'framer-motion'

const Button = ({onClick, className, children}) => {
    return (
        <motion.button 
            className={`text-center text-sm leading-normal text-white py-1.5 px-14 bg-primary rounded-6xl border border-primary ${className} hover:font-bold hover:bg-transparent`} onClick={onClick}
            whileHover={{
                color: "#6736FF",
            }}
            transition={{
                duration: 0.2,
            }}
        >{children}</motion.button>
    )
}

export default Button