import { motion } from 'framer-motion'
import React from 'react'

const Backdrop = ({children, onClick}) => {
  return (
      <motion.div className="fixed top-0 left-0 bottom-0 right-0 bg-[#000000e1] flex justify-center items-center overflow-y-scroll py-20 z-[10000] backdrop" 
        onMouseDown={onClick}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        transition={{duration:0.1}}>
          {children}
      </motion.div>
  )
}

export default Backdrop