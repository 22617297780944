import { useContext, useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { AccountContext } from "./AuthProvider";

const AuthGuard = () => {
    const { userProfile, getUserProfile } = useContext(AccountContext)
    const [loaded, setLoaded] = useState(false)
    const location = useLocation();


    async function loadUser() {
        setLoaded(false)
        await getUserProfile()
        setLoaded(true)
    }

    useEffect(() => {
        loadUser()
    }, [])
  
  
    return (
        loaded ? 
            userProfile ? <Outlet /> : <Navigate to="/login" state={{ ...location.state, from: location.pathname === '/login' ? '/' : location.pathname }} replace />
            
            :

            <div className="h-screen">
                
            </div>
    );
}

export default AuthGuard;