import React from 'react'
import './Spinner.css'

const Spinner = ({className}) => {
    return ( 
        // <img src="/global/logo-animated.svg" alt="Loading" className={className}/>
        
        <img src="/global/spinner.svg" alt="Loading" className={className}/>
    )
}

export default Spinner