import React from 'react'
import { NavLink } from 'react-router-dom'

const NavItemMobile = ({link, children}) => {
    return (
        <NavLink to={link} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? "h-fit text-white font-semibold": 'h-fit text-white'}>
            <p className="w-full text-left text-2xl">
                {children}
            </p>
        </NavLink>
    )
}

export default NavItemMobile