import React from 'react'

const Textarea = ({name, value, label, onChange, className}) => {
  return (
    <div className="flex flex-col gap-y-1">
        <label htmlFor={`input#${name}`} className="text-xs leading-normal text-primary font-semibold">{label}</label>
        <textarea id={`input#${name}`} name={name} value={value} onChange={onChange}className={`py-2.5 px-3 outline-0 text-sm border border-grey rounded-md w-full resize-none ${className}`} />
    </div>
  )
}

export default Textarea