import { useContext, useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { AccountContext } from "./AuthProvider";

const LoginGuard = () => {
    const { userProfile, getUserProfile } = useContext(AccountContext)

    const [loaded, setLoaded] = useState(false)
    const location = useLocation();
    useEffect(() => {
        setLoaded(false)
        getUserProfile()

    }, [])
  
    useEffect(() => {
        setLoaded(true)
    }, [userProfile])

    return (
        loaded ? 
            !userProfile ? <Outlet /> : <Navigate to="/" replace />
            
            :

            <div className="h-screen">
                
            </div>
    );
}

export default LoginGuard;