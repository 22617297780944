import React from 'react'
import { NavLink } from 'react-router-dom'

const NavItem = ({link, children}) => {
  return (
    <NavLink to={link} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? "h-fit text-primary font-semibold": 'h-fit text-black'}>
        {children}
    </NavLink>
  )
}

export default NavItem