import React, { useContext, useEffect, useState } from 'react'
import { Outlet } from "react-router-dom"
import Navbar from '../Navbar'
import { motion } from 'framer-motion'
import Footer from '../Footer'
import { AccountContext } from '../../auth/AuthProvider'

const Layout = () => {  
  const { getUserProfile } = useContext(AccountContext)
  
  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.4
  }; 

  useEffect(() => {
      getUserProfile()
  }, [])


  return (
    <>
        <Navbar/>

        <motion.div 
            className="bg-layout bg-top bg-cover"
            initial="initial"
            animate="in"
            variants={pageVariants}
            transition={pageTransition}
          >
              <Outlet />
           
        </motion.div>
        
        <Footer />
    </>
  )
}

export default Layout