import { API, Auth, graphqlOperation } from 'aws-amplify'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { getUserProfile as getUserProfileFromAWS } from '../graphql/queries'

export const AccountContext = createContext()

const AuthProvider = ({children}) => {
    const [userProfile, setUserProfile] = useState(null)
    const [userData, setUserData] = useState(null)


    async function getUserData() {
        try {
            const result = await API.graphql({...graphqlOperation(getUserProfileFromAWS), authMode: 'AMAZON_COGNITO_USER_POOLS'})
            setUserData(result.data.getUserProfile)
        } catch (e) {
            console.log(e)
        }
    }

    const getUserProfile = async () => {
        try {
            setUserProfile(await Auth.currentAuthenticatedUser())
        }
        catch(err) {
            setUserProfile(null)
        }
    }

    useEffect(() => {
        if(!userData) {
            getUserData()
        }
    }, [userProfile])


      
    const value = useMemo(() => ({userProfile, getUserProfile, userData, getUserData}), [userProfile, getUserProfile, userData, getUserData])
    
    return (
        <AccountContext.Provider value={value}>
            {children}
        </AccountContext.Provider>
        
    )
}

export default AuthProvider