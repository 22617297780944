import React, { useState } from 'react'
import Container from '../Container'
import { Link } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import BecomeAnInstructor from '../BecomeAnInstructor'

const Footer = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [submissionState, setSubmissionState] = useState('idle')

    const closeModal = () => {
        setIsModalOpen(false);
    }
    const openModal = () => setIsModalOpen(true);

    return (
        <footer className="bg-space-grey pb-5 pt-16 relative">
            <button className="hidden lg:block absolute top-[calc(50%+5rem)] translate-y-[calc(-50%-4rem)] right-10" onClick={() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }}>
                <img src="/icons/arrow-top.svg" alt="Go to top of page" />
            </button>
            <Container className="flex flex-col-reverse md:flex-row gap-8 mt-2">
                <div className="w-full md:w-4/12 mt-auto">
                    <img src="/global/logo-alt.svg" alt="The Market Institution Logo" className="w-3/4 mb-5"/>
                    <p className="text-xs text-white">TheMarketInstitution.com 2023 All Rights Reserved</p>
                </div>
                <div className="w-full md:w-4/12 text-sm leading-normal text-white grid grid-cols-3 justify-center items-center mb-12 gap-6">
                        <Link to="/courses" className="text-center hover:font-semibold" style={{ textDecoration: "none" }} >
                            Courses
                        </Link>
                        <Link to="/schedules" className="text-center hover:font-semibold" style={{ textDecoration: "none" }} >
                            Schedules
                        </Link>
                        <Link to="/contact-us" className="text-center hover:font-semibold" style={{ textDecoration: "none" }} >
                            Contact Us
                        </Link>
                        <Link to="/terms-of-use" className="text-center hover:font-semibold" style={{ textDecoration: "none" }} >
                            Terms of Use
                        </Link>
                        <Link to="/disclaimer" className="text-center hover:font-semibold" style={{ textDecoration: "none" }} >
                            Disclaimer
                        </Link>
                        <Link to="/privacy-policy" className="text-center hover:font-semibold" style={{ textDecoration: "none" }} >
                            Privacy Policy
                        </Link>
                </div>
                <div className="flex-1 flex flex-col items-end justify-end gap-8 md:mb-0 mb-8">
                    <button className="px-6 py-2 text-sm font-bold leading-normal bg-white hover:bg-transparent text-primary hover:text-white hover:border hover:border-white rounded-3xl" onClick={() => openModal()}>BECOME AN INSTRUCTOR</button>
                    <div className="flex flex-row gap-x-2.5">
                        <Link to="https://www.youtube.com/@TheMarketInstitution" target="_blank">
                            <img src="/icons/youtube-icon.svg" alt="Youtube" />
                        </Link>
                        <Link to="https://instagram.com/themarketinstitution?igshid=MzMyNGUyNmU2YQ==" target="_blank">
                            <img src="/icons/instagram-icon.svg" alt="Instagram" />
                        </Link>
                        <Link to="https://twitter.com/learnwithtmi" target="_blank">
                            <img src="/icons/x-icon.svg" alt="X" />
                        </Link>
                        <Link to="https://www.facebook.com/people/The-Market-Institution/100095546100966/?mibextid=ZbWKwL" target="_blank">
                            <img src="/icons/facebook-icon.svg" alt="Facebook" />
                        </Link>

                    </div>
                </div>

            </Container>

            {isModalOpen && 
                <AnimatePresence mode="wait">
                    <BecomeAnInstructor isModalOpen={isModalOpen} closeModal={closeModal} submissionState={submissionState} setSubmissionState={setSubmissionState}/>
                </AnimatePresence>
            }

        </footer>
    )
}

export default Footer