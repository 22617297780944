import React, { useReducer, useState } from 'react'
import Modal from '../Modal'
import Input from '../Input'
import { API, graphqlOperation } from 'aws-amplify'
import ModalBody from '../Modal/ModalBody'
import Button from '../Button'
import Textarea from '../Textarea'
import { becomeAnInstructorContactMessage } from '../../graphql/mutations'
import Spinner from '../Spinner'

const initialState = {
    email: '',
    name: '',
    nationality: '',
    message: '',

    forex: false,
    futures: false,
    stocks: false,
    others: ''
}
function reducer(state, action) {
    switch(action.type) {
        case 'SET_INPUT':
            return {...state, [action.key]: action.value}
        case 'SET_INITIAL':
            return {...state, ...initialState}
        default:
            return state
    }
}
const BecomeAnInstructor = ({isModalOpen, closeModal, submissionState, setSubmissionState}) => {

    const [state, dispatch] = useReducer(reducer, initialState)

    const [emailError, setEmailError] = useState(null)
    const [tradeError, setTradeError] = useState(null)
    const [nameError, setNameError] = useState(null)
    const [nationalityError, setNationalityError] = useState(null)

    function onChange(e) {
        dispatch({type: 'SET_INPUT', key: e.target.name, value: e.target.value})
        if(e.target.name === 'email') {
            setEmailError(null)
        }
        if(e.target.name === 'name') {
            setNameError(null)
        }
        if(e.target.name === 'nationality') {
            setNationalityError(null)
        }
        if(e.target.name === 'others') {
            setTradeError(null)
        }
    }

    function onCheck(e) {
        dispatch({type: 'SET_INPUT', key: e.target.name, value: !state[e.target.name]})
        setTradeError(null)
    }

    async function onSubmit(e) {
        e.preventDefault()
        var error = false
        setSubmissionState('loading')

        if(!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email))) {
            setEmailError('Invalid email address')
            error = true
        }
        if(!(/\S/.test(state.name))) {
            setNameError('Please specify your name')
            error = true
        }
        if(!(/\S/.test(state.nationality))) {
            setNationalityError('Please specify your nationality')
            error = true
        }
        if(!(/\S/.test(state.others) || state.forex || state.futures || state.stocks)) {
            setTradeError('Please select a trade / specify others')
            error = true
        }
        if(!error) {
            try {
                var trades = ''
                if(state.forex) trades += trades.length ? ', Forex' : 'Forex'
                if(state.futures) trades += trades.length ? ', Futures' : 'Futures'
                if(state.stocks) trades += trades.length ? ', US Stocks' : 'US Stocks'
                if(state.others) trades += trades.length ? `, ${state.others}` : state.others
                
                const result = await API.graphql({...graphqlOperation(becomeAnInstructorContactMessage, {input: {
                    email: state.email,
                    name: state.name,
                    trades: trades,
                    nationality: state.nationality,
                    message: state.message
                }})})
                if(result.data.becomeAnInstructorContactMessage.success) {
                    setSubmissionState('submitted')
                }
            } catch (e) {
                console.log(e)
                setSubmissionState('idle')
            }
        }
    }

    return (
        <Modal modalOpen={isModalOpen} handleClose={() => closeModal()}>
            <ModalBody>
                {submissionState === 'idle' &&
                    <>
                        <p className="text-xl font-semibold text-black text-center mb-4">Please fill the form below</p>
                        <form className="flex flex-col w-full gap-4" onSubmit={onSubmit}>
                            <Input type="email" name="email" label="Email" value={state.email} onChange={onChange} error={emailError}/>
                            <Input type="text" name="name" label="Name" value={state.name} onChange={onChange} error={nameError}/>
                            <Input type="text" name="nationality" label="Nationality" value={state.nationality} onChange={onChange} error={nationalityError}/>
                            <div>
                                <p className="text-xs leading-normal text-primary font-semibold mb-1">I Trade</p>
                                <div className="flex flex-col gap-x-1 pl-4">
                                    <label className="flex flex-row items-center gap-x-2 text-sm w-fit">
                                        <input
                                            type="checkbox"
                                            name="forex"
                                            checked={state.forex}
                                            onChange={onCheck}
                                            className="accent-primary focus:accent-primary"
                                        />
                                        Forex
                                    </label>
                                    <label className="flex flex-row items-center gap-x-2 text-sm w-fit">
                                        <input
                                            type="checkbox"
                                            name="futures"
                                            checked={state.futures}
                                            onChange={onCheck}
                                            className="accent-primary focus:accent-primary"
                                        />
                                        Futures
                                    </label>
                                    <label className="flex flex-row items-center gap-x-2 text-sm w-fit">
                                        <input
                                            type="checkbox"
                                            name="stocks"
                                            checked={state.stocks}
                                            onChange={onCheck}
                                            className="accent-primary focus:accent-primary"
                                        />
                                        US Stocks
                                    </label>
                                    <div className="flex flex-row gap-x-2">
                                        <label className="flex flex-row items-center gap-x-2 text-sm">
                                            Others: 
                                        </label>
                                        <input type="text" name="others" value={state.others} onChange={onChange}className="outline-0 text-sm border-b border-grey w-32" />
                                    </div>
                                </div>
                                {tradeError != null && 
                                    <small className="text-red text-2xs">{tradeError}</small>
                                }
                            </div>
                            <Textarea name="message" label="Message" className="h-full" value={state.message} onChange={onChange}/>
                            <Button className="mt-8 ml-auto">Send</Button>
                        </form>
                    </>
                }
                {submissionState === 'loading' &&
                    <div className="w-full flex flex-col py-12 px-7 pb-7 items-center justify-center">
                        <Spinner />
                    </div>
                
                }

                {submissionState === 'submitted' &&
                    <div className="w-full flex flex-col py-12 px-7 pb-7">
                        <p class="text-xl mb-4 text-center">Thank you for the submission. We will be in touch with you shortly.</p>
                    </div>                
                }

            </ModalBody>
        </Modal>
    )
}

export default BecomeAnInstructor