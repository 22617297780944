import { motion } from 'framer-motion'
import React from 'react'
import Backdrop from './Backdrop'

const dropIn = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
          duration: 0.1,
          type: "easeOut",
        },
    },
    exit: {
        opacity: 0
    }
}

const Modal = ({handleClose, children, className}) => {
  return (
      <Backdrop onClick={handleClose}>
          <motion.div className={`relative min-w-[50%] w-[700px] max-w-[90%] h-fit m-auto flex flex-col items-center bg-white ${className}`}
            onMouseDown={(e) => e.stopPropagation()}
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit">
              {children}
          </motion.div>
      </Backdrop>
  )
}

export default Modal