/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addProduct = /* GraphQL */ `
  mutation AddProduct($input: AddProductInput) {
    addProduct(input: $input) {
      PK
      SK
      baseType
      createdAt
      type
      paymentPlans
      link
      name
      image
      preview
      details
      price
      isLive
      owned
      hasPMR
      inWishlist
      tags
      productsInBundle
      difficulty
      instructors
      sections
      GSI1PK
      GSI1SK
      status
      lastEvaluatedKey
      errors
    }
  }
`;
export const setProduct = /* GraphQL */ `
  mutation SetProduct($input: SetProductInput) {
    setProduct(input: $input) {
      PK
      SK
      baseType
      createdAt
      type
      paymentPlans
      link
      name
      image
      preview
      details
      price
      isLive
      owned
      hasPMR
      inWishlist
      tags
      productsInBundle
      difficulty
      instructors
      sections
      GSI1PK
      GSI1SK
      status
      lastEvaluatedKey
      errors
    }
  }
`;
export const addProductSchedule = /* GraphQL */ `
  mutation AddProductSchedule($input: AddProductScheduleInput) {
    addProductSchedule(input: $input) {
      PK
      SK
      baseType
      details
      date
      start
      end
      link
      name
      type
      description
      GSI1PK
      GSI1SK
      instructors
      lastEvaluatedKey
      errors
    }
  }
`;
export const updateProductSchedule = /* GraphQL */ `
  mutation UpdateProductSchedule($input: UpdateProductScheduleInput) {
    updateProductSchedule(input: $input) {
      PK
      SK
      baseType
      details
      date
      start
      end
      link
      name
      type
      description
      GSI1PK
      GSI1SK
      instructors
      lastEvaluatedKey
      errors
    }
  }
`;
export const removeProductSchedule = /* GraphQL */ `
  mutation RemoveProductSchedule($input: RemoveProductScheduleInput) {
    removeProductSchedule(input: $input) {
      success
      errors
    }
  }
`;
export const setInstructorProduct = /* GraphQL */ `
  mutation SetInstructorProduct($input: SetProductInput) {
    setInstructorProduct(input: $input) {
      PK
      SK
      baseType
      createdAt
      type
      paymentPlans
      link
      name
      image
      preview
      details
      price
      isLive
      owned
      hasPMR
      inWishlist
      tags
      productsInBundle
      difficulty
      instructors
      sections
      GSI1PK
      GSI1SK
      status
      lastEvaluatedKey
      errors
    }
  }
`;
export const addInstructorPromoCode = /* GraphQL */ `
  mutation AddInstructorPromoCode($input: SetPromoCodeInput) {
    addInstructorPromoCode(input: $input) {
      PK
      SK
      baseType
      code
      price
      products
      GSI1PK
      GSI1SK
      validFrom
      validUntil
      isLimited
      amountRemaining
      createdAt
      updatedAt
      lastEvaluatedKey
      errors
    }
  }
`;
export const updateInstructorPromoCode = /* GraphQL */ `
  mutation UpdateInstructorPromoCode($input: SetPromoCodeInput) {
    updateInstructorPromoCode(input: $input) {
      PK
      SK
      baseType
      code
      price
      products
      GSI1PK
      GSI1SK
      validFrom
      validUntil
      isLimited
      amountRemaining
      createdAt
      updatedAt
      lastEvaluatedKey
      errors
    }
  }
`;
export const removeInstructorPromoCode = /* GraphQL */ `
  mutation RemoveInstructorPromoCode($input: RemovePromoCodeInput) {
    removeInstructorPromoCode(input: $input) {
      success
      errors
    }
  }
`;
export const addInstructorProductPaymentPlan = /* GraphQL */ `
  mutation AddInstructorProductPaymentPlan($input: SetProductPaymentPlanInput) {
    addInstructorProductPaymentPlan(input: $input) {
      id
      name
      price
      description
      interval
      interval_count
      errors
    }
  }
`;
export const setInstructorProductPaymentPlan = /* GraphQL */ `
  mutation SetInstructorProductPaymentPlan(
    $input: SetProductPaymentPlansInput
  ) {
    setInstructorProductPaymentPlan(input: $input) {
      id
      name
      price
      description
      interval
      interval_count
      errors
    }
  }
`;
export const addProductInBundle = /* GraphQL */ `
  mutation AddProductInBundle($input: SetProductInBundleInput) {
    addProductInBundle(input: $input) {
      products
      errors
    }
  }
`;
export const removeProductInBundle = /* GraphQL */ `
  mutation RemoveProductInBundle($input: SetProductInBundleInput) {
    removeProductInBundle(input: $input) {
      products
      errors
    }
  }
`;
export const addProductInPromoCode = /* GraphQL */ `
  mutation AddProductInPromoCode($input: SetProductInPromoCode) {
    addProductInPromoCode(input: $input) {
      products
      errors
    }
  }
`;
export const removeProductInPromoCode = /* GraphQL */ `
  mutation RemoveProductInPromoCode($input: SetProductInPromoCode) {
    removeProductInPromoCode(input: $input) {
      products
      errors
    }
  }
`;
export const publishProduct = /* GraphQL */ `
  mutation PublishProduct($input: PublishInput) {
    publishProduct(input: $input) {
      PK
      SK
      baseType
      createdAt
      type
      paymentPlans
      link
      name
      image
      preview
      details
      price
      isLive
      owned
      hasPMR
      inWishlist
      tags
      productsInBundle
      difficulty
      instructors
      sections
      GSI1PK
      GSI1SK
      status
      lastEvaluatedKey
      errors
    }
  }
`;
export const unpublishProduct = /* GraphQL */ `
  mutation UnpublishProduct($input: PublishInput) {
    unpublishProduct(input: $input) {
      PK
      SK
      baseType
      createdAt
      type
      paymentPlans
      link
      name
      image
      preview
      details
      price
      isLive
      owned
      hasPMR
      inWishlist
      tags
      productsInBundle
      difficulty
      instructors
      sections
      GSI1PK
      GSI1SK
      status
      lastEvaluatedKey
      errors
    }
  }
`;
export const addProductPreMarketRoutine = /* GraphQL */ `
  mutation AddProductPreMarketRoutine($input: AddProductPreMarketRoutineInput) {
    addProductPreMarketRoutine(input: $input) {
      PK
      SK
      baseType
      date
      indexFutures
      preMarketTips
      whatsInTheNews
      economicReports
      images
      GSI1PK
      GSI1SK
      createdAt
      updatedAt
      lastEvaluatedKey
      errors
    }
  }
`;
export const purchaseFreeProduct = /* GraphQL */ `
  mutation PurchaseFreeProduct($input: LinkInput) {
    purchaseFreeProduct(input: $input) {
      success
      errors
    }
  }
`;
export const setUserProfile = /* GraphQL */ `
  mutation SetUserProfile($input: SetUserProfileInput) {
    setUserProfile(input: $input) {
      PK
      SK
      baseType
      createdAt
      updatedAt
      name
      description
      birthdate
      phone_number
      email
      lastEvaluatedKey
      errors
    }
  }
`;
export const setSections = /* GraphQL */ `
  mutation SetSections($input: SetSectionInput) {
    setSections(input: $input) {
      PK
      SK
      baseType
      details
      createdAt
      updatedAt
      errors
    }
  }
`;
export const addProductReview = /* GraphQL */ `
  mutation AddProductReview($input: AddProductReviewInput) {
    addProductReview(input: $input) {
      PK
      SK
      baseType
      link
      rating
      review
      censored
      GSI1PK
      GSI1SK
      createdAt
      updatedAt
      errors
    }
  }
`;
export const toggleWishlist = /* GraphQL */ `
  mutation ToggleWishlist($input: LinkInput) {
    toggleWishlist(input: $input) {
      success
      errors
    }
  }
`;
export const cancelSubscription = /* GraphQL */ `
  mutation CancelSubscription($input: CancelSubscriptionInput) {
    cancelSubscription(input: $input) {
      success
      errors
    }
  }
`;
export const addInstructor = /* GraphQL */ `
  mutation AddInstructor($input: AddInstructorInput) {
    addInstructor(input: $input) {
      success
      errors
    }
  }
`;
export const editInstructor = /* GraphQL */ `
  mutation EditInstructor($id: String) {
    editInstructor(id: $id) {
      PK
      SK
      baseType
      name
      description
      birthdate
      phone_number
      email
      lastEvaluatedKey
      errors
    }
  }
`;
export const removeInstructor = /* GraphQL */ `
  mutation RemoveInstructor($id: String) {
    removeInstructor(id: $id) {
      PK
      SK
      baseType
      name
      description
      birthdate
      phone_number
      email
      lastEvaluatedKey
      errors
    }
  }
`;
export const setHomeSettings = /* GraphQL */ `
  mutation SetHomeSettings($input: SetHomeSettingsInput) {
    setHomeSettings(input: $input) {
      success
      errors
    }
  }
`;
export const setTradeOfTheWeek = /* GraphQL */ `
  mutation SetTradeOfTheWeek($input: SetTradeOfTheWeekInput) {
    setTradeOfTheWeek(input: $input) {
      success
      errors
    }
  }
`;
export const uncensorReview = /* GraphQL */ `
  mutation UncensorReview($input: IdLinkInput) {
    uncensorReview(input: $input) {
      success
      errors
    }
  }
`;
export const censorReview = /* GraphQL */ `
  mutation CensorReview($input: IdLinkInput) {
    censorReview(input: $input) {
      success
      errors
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview($input: IdLinkInput) {
    deleteReview(input: $input) {
      success
      errors
    }
  }
`;
export const addStudentToProduct = /* GraphQL */ `
  mutation AddStudentToProduct($input: AddStudentToProductInput) {
    addStudentToProduct(input: $input) {
      success
      errors
    }
  }
`;
export const removeStudentFromProduct = /* GraphQL */ `
  mutation RemoveStudentFromProduct($input: IdLinkInput) {
    removeStudentFromProduct(input: $input) {
      success
      errors
    }
  }
`;
export const setInstructorCommission = /* GraphQL */ `
  mutation SetInstructorCommission($input: InstructorCommissionInput) {
    setInstructorCommission(input: $input) {
      PK
      SK
      baseType
      details
      lastEvaluatedKey
      errors
    }
  }
`;
export const sendContactUsMessage = /* GraphQL */ `
  mutation SendContactUsMessage($input: ContactUsMessageInput) {
    sendContactUsMessage(input: $input) {
      success
      errors
    }
  }
`;
export const becomeAnInstructorContactMessage = /* GraphQL */ `
  mutation BecomeAnInstructorContactMessage(
    $input: BecomeAnInstructorContactMessageInput
  ) {
    becomeAnInstructorContactMessage(input: $input) {
      success
      errors
    }
  }
`;
