import './App.css';
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import AuthProvider from './auth/AuthProvider';
import LoginGuard from './auth/LoginGuard';
import AuthGuard from './auth/AuthGuard';
import Spinner from './components/Spinner';

const Login = React.lazy(() => import("./pages/Login"));
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
const CompleteForgotPassword = React.lazy(() => import("./pages/CompleteForgotPassword"));
const Register = React.lazy(() => import("./pages/Register"));
const SpecialRegistration = React.lazy(() => import("./pages/SpecialRegistration"));
const VerifyEmail = React.lazy(() => import("./pages/VerifyEmail"));
const CompleteSignup = React.lazy(() => import("./pages/CompleteSignup"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const Products = React.lazy(() => import("./pages/Products"));
const ProductDetails = React.lazy(() => import("./pages/Products/ProductDetails"));
const Checkout = React.lazy(() => import("./pages/Checkout"));
const PostCheckout = React.lazy(() => import("./pages/PostCheckout"));
const MyCourses = React.lazy(() => import("./pages/MyCourses"));
const MyCourseDetails = React.lazy(() => import("./pages/MyCourses/MyCourseDetails"));
const Livestream = React.lazy(() => import("./pages/MyCourses/MyCourseDetails/Livestream"));
const Profile = React.lazy(() => import("./pages/Profile"));
const OrderHistory = React.lazy(() => import("./pages/OrderHistory"));
const Wishlist = React.lazy(() => import("./pages/Wishlist"));
const Schedules = React.lazy(() => import("./pages/Schedules"));
const MySubscriptions = React.lazy(() => import("./pages/MySubscriptions"))
const MySubscriptionDetails = React.lazy(() => import("./pages/MySubscriptions/MySubscriptionDetails"))
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"))
const TermsOfUse = React.lazy(() => import("./pages/TermsOfUse"))
const Disclaimer = React.lazy(() => import("./pages/Disclaimer"))

const Home = React.lazy(() => import("./pages/Home"));

export default function App() {
	const location = useLocation()

    return (
        <AuthProvider>
            <Routes location={location} key={location.pathname}>
                <Route exact path="/" element={<Layout />}>
                    <Route
                        path="/"
                        index
                        element={
                        <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                            <Home />
                        </React.Suspense>
                        }
                    />
                    <Route
                        path="/schedules"
                        element={
                        <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                            <Schedules />
                        </React.Suspense>
                        }
                    />
                    <Route
                        path="/courses"
                        element={
                        <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                            <Products />
                        </React.Suspense>
                        }
                    />
                    <Route
                        path="/courses/:link"
                        element={
                        <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                            <ProductDetails />
                        </React.Suspense>
                        }
                    />
                    <Route exact path="/complete-signup" element={
                        <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                            <CompleteSignup />
                        </React.Suspense>
                    }/>
                    <Route
                        path="/contact-us"
                        element={
                        <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                            <ContactUs />
                        </React.Suspense>
                        }
                    />
                    <Route
                        path="/terms-of-use"
                        element={
                        <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                            <TermsOfUse />
                        </React.Suspense>
                        }
                    />
                    <Route
                        path="/disclaimer"
                        element={
                        <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                            <Disclaimer />
                        </React.Suspense>
                        }
                    />
                    <Route
                        path="/privacy-policy"
                        element={
                        <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                            <PrivacyPolicy />
                        </React.Suspense>
                        }
                    />


                    {/*  PROTECTED ROUTES */ }
                    <Route element={<AuthGuard />}>
                        <Route
                            path="/checkout"
                            element={
                                <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                                    <Checkout />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/post-checkout"
                            element={
                                <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                                    <PostCheckout />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/wishlist"
                            element={
                                <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                                    <Wishlist />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/my-courses"
                            element={
                                <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                                    <MyCourses />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/my-courses/:link"
                            element={
                                <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                                    <MyCourseDetails />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/profile"
                            element={
                                <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                                    <Profile />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/my-subscriptions"
                            element={
                                <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                                    <MySubscriptions />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/my-subscriptions/:id"
                            element={
                                <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                                    <MySubscriptionDetails />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/order-history"
                            element={
                                <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                                    <OrderHistory />
                                </React.Suspense>
                            }
                        />
                    </Route>

                    {/*  REVERSE PROTECTED ROUTES */ }
                    <Route element={<LoginGuard />}>
                        <Route exact path="/login" element={
                            <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                                <Login />
                            </React.Suspense>
                        }/>
                        <Route exact path="/register" element={
                            <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                                <Register />
                            </React.Suspense>
                        }/>
                        <Route exact path="/special-registration" element={
                            <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                                <SpecialRegistration />
                            </React.Suspense>
                        }/>
                        <Route exact path="/verify" element={
                            <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                                <VerifyEmail />
                            </React.Suspense>
                        }/>
                        <Route exact path="/forgot-password" element={
                            <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                                <ForgotPassword />
                            </React.Suspense>
                        }/>
                        <Route exact path="/complete-forgot-password" element={
                            <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                                <CompleteForgotPassword />
                            </React.Suspense>
                        }/>
                    </Route>
                </Route>
                
                <Route element={<AuthGuard />}>

                    <Route
                        path="/my-courses/:link/live"
                        element={
                            <React.Suspense fallback={<div className="min-h-screen h-screen flex flex-col items-center justify-center"><Spinner /></div>}>
                                <Livestream />
                            </React.Suspense>
                        }
                    />
                </Route>
            </Routes>
        </AuthProvider>
    )
}
