import React from 'react'

const ModalBody = ({children}) => {
  return (
    <div className="w-full p-8">
      {children}
    </div>
  )
}

export default ModalBody