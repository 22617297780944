import React from 'react'

const Input = ({type, name, value, label, onChange, error}) => {
  return (
    <div className="flex flex-col gap-y-1">
        <label htmlFor={`input#${name}`} className="text-xs leading-normal text-primary font-semibold">{label}</label>
        <input type={type} id={`input#${name}`} name={name} value={value} onChange={onChange} className={`py-2.5 px-3 outline-0 text-sm border ${error ? 'border-red' : 'border-grey'} rounded-md w-full`}/>
        {error != null && 
          <small className="text-red text-2xs">{error}</small>
        }
    </div>
  )
}

export default Input