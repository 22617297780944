import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import NavItem from './NavItem'
import { AccountContext } from '../../auth/AuthProvider'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { createAvatar } from '@dicebear/core';
import { shapes } from '@dicebear/collection';
import { motion } from 'framer-motion'
import NavItemMobile from './NavItemMobile'
import { getHomeSettings } from '../../graphql/queries'
import DOMPurify from 'dompurify'

var announcementShown = true
const Navbar = () => {
    const {userData, userProfile, getUserProfile} = useContext(AccountContext)

    const [profilePictureSrc, setProfilePictureSrc] = useState(null)
    const [imageError, setImageError] = useState(false)
    const [mobileMenuShown, setMobileMenuShown] = useState(false)

    const [profleMobileShown, setProfleMobileShown] = useState(false)

    const [reload, setReload] = useState(null)

    const [showBanner, setShowBanner] = useState(false);

    const [topNotificationSettings, setTopNotificationSettings] = useState();

    async function logout() {
        try {
            await Auth.signOut();
            await getUserProfile()
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    async function getWebSettings() {
        try {
            const {data} = await API.graphql(graphqlOperation(getHomeSettings))

            if(data.getHomeSettings.settings && JSON.parse(data.getHomeSettings.settings).top_notification) {

                setTopNotificationSettings(JSON.parse(data.getHomeSettings.settings).top_notification)
                const bannerClosedAt = localStorage.getItem('bannerClosedAt');
                if (bannerClosedAt) {
                    const closedTimestamp = new Date(bannerClosedAt).getTime();
                    const currentTimestamp = new Date().getTime();
                        
                    // Check if 24 hours have passed since the banner was closed
                    if (currentTimestamp - closedTimestamp > 24 * 60 * 60 * 1000) {
                        setShowBanner(true);
                    }
                }
                else {
                    setShowBanner(true);
                }
            }            
        } catch (e) {
            console.log(e)
        }
    }


    useEffect(() => {
        getWebSettings()
    }, []);

    const handleBannerClose = () => {
        localStorage.setItem('bannerClosedAt', new Date().toISOString());
        setShowBanner(false);
    };
    

    useEffect(() => {
        if(userProfile) {
            setProfilePictureSrc(`https://d1yr1ar2im5b78.cloudfront.net/public/profile/${userProfile.attributes.sub}.jpeg`)
        }
    }, [userProfile])


    const memoized = useMemo(() => ({profilePictureSrc, userProfile}), [profilePictureSrc, userProfile])

    return (
        <>
            <header className="lg:absolute lg:bg-transparent bg-white fixed top-0 left-0 z-50 w-full">
                {showBanner && topNotificationSettings && topNotificationSettings.description &&
                    <div className="bg-[#F35A59] w-full mx-auto lg:px-24 md:px-12 px-5 py-2 flex flex-row justify-center items-center text-sm text-white text-center relative">
                        {topNotificationSettings.link ? 
                            topNotificationSettings.top_notification_link_type === 'internal' ?
                                <Link to={`${topNotificationSettings.link}`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(topNotificationSettings.description) }}>
                                    
                                </Link>

                                :
                                
                                <Link to={`${topNotificationSettings.link}`} target="_blank" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(topNotificationSettings.description) }}>
                                    
                                </Link>
                            :
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(topNotificationSettings.description) }}></div>
                        }
                        <button className="absolute px-2 right-0 top-0 flex flex-col justify-center items-center h-full" onClick={() => handleBannerClose()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                <line x1="1" y1="1" x2="11" y2="11" stroke="#ffffff" strokeWidth="2"/>
                                <line x1="1" y1="11" x2="11" y2="1" stroke="#ffffff" strokeWidth="2"/>
                            </svg>
                        </button>
                    </div>
                }
                <nav className="2xl:max-w-7xl xl:max-w-6xl w-full mx-auto lg:px-24 lg:pt-12 lg:pb-10 md:px-12 px-5 py-6 flex flex-row gap-x-4 justify-between items-center text-sm shadow-lg lg:shadow-none">
                    <Link to="/" style={{ textDecoration: "none" }} className="w-2/12" >
                        <img src="/global/logo.svg" alt="The Market Institution Logo" className="md:block hidden"/>
                        <img src="/global/logo-small.svg" alt="The Market Institution Logo" className="md:hidden"/>

                    </Link>
                    <div className={`hidden md:flex flex-row ${(userProfile) ? 'w-6/12' :'w-4/12'} justify-around`}>
                        <NavItem link='/courses'>Courses</NavItem>
                        <NavItem link='/schedules'>Schedules</NavItem>
                        <NavItem link='/contact-us'>Contact Us</NavItem>
                        {userProfile && <NavItem link='/my-courses'>My Courses</NavItem>}
                    </div>
                    {   

                            memoized.userProfile ? 
                                
                                <div className="hidden md:flex flex-row ml-auto justify-end items-center gap-x-3 group relative w-4/12">
                                    <p>{userData && userData.name ? userData.name : userProfile.attributes.email}</p>
                                    {/* <p>{ userProfile.attributes.email}</p> */}
                                    {memoized.profilePictureSrc && 
                                        <img 
                                            src={memoized.profilePictureSrc}  
                                            alt="Profile Picture" 
                                            className="w-8 h-8 object-cover object-center rounded-full"
                                            onError={(e) => {
                                            e.target.src = '/global/no-profile.jpg';
                                            }}
                                        />
                                    }
                                    <div className="absolute right-0 top-full w-3/4 h-fit pt-4 hidden group-hover:block z-50">
                                        <div className="bg-white border border-medium-grey pt-6 pb-3 px-4">
                                            <div className="py-3 flex flex-col items-center gap-y-4 pb-6">
                                                {memoized.profilePictureSrc && 
                                                    <img 
                                                        src={memoized.profilePictureSrc}  
                                                        alt="Profile Picture" 
                                                        className="w-16 h-16 object-cover object-center rounded-full"
                                                        onError={(e) => {
                                                        e.target.src = '/global/no-profile.jpg';
                                                        }}
                                                    />
                                                }
                                                <div>
                                                    <p className="text-sm leading-snug font-semibold text-center">{userData && userData.name ? userData.name : userProfile.attributes.email}</p>
                                                    <p className="text-2xs leading-[1.8] overflow-hidden text-center">{ userProfile.attributes.email }</p>
                                                </div>
                                            </div>
                                            <NavItem link='/profile'>
                                                <div className="py-3">
                                                    My Profile
                                                </div>
                                            </NavItem>
                                            <NavItem link='/wishlist'>
                                                <div className="py-3">
                                                    Wishlist
                                                </div>
                                            </NavItem>
                                            <NavItem link='/my-subscriptions'>
                                                <div className="py-3">
                                                    My Subscriptions
                                                </div>
                                            </NavItem>
                                            <NavItem link='/order-history'>
                                                <div className="py-3">
                                                    Order History
                                                </div>
                                            </NavItem>
                                            <button className="py-3" onClick={logout}>
                                                Logout
                                            </button>

                                        </div>
                                    </div>
                                </div>


                                :

                                <div className="hidden md:flex flex-row w-3/12 ml-auto justify-between items-center gap-x-4 lg:gap-x-0">
                                    <NavItem link={localStorage.getItem('registration_source') ? '/special-registration' : '/register'}>Register</NavItem>
                                    <NavLink to='/login' style={{ textDecoration: "none" }} className="w-2/3 text-center text-white py-1.5 pb-2 bg-primary rounded-6xl">
                                        Login
                                    </NavLink>
                                </div>
                    }
                    <button className="block md:hidden" onClick={() => setMobileMenuShown(true)}>
                        <img src="/icons/hamburger.svg" alt="Menu Button" className="h-5"/>
                    </button>
                    <motion.div className="block md:hidden fixed top-0 bottom-0 left-[100%] w-screen shadow-lg shadow-" onClick={() => setMobileMenuShown(false)}
                        initial={{left: '100%'}}
                        animate={{left: mobileMenuShown ? 0 : '100%' }}
                        transition={{ease: 'easeOut', duration: 0.5}}
                    >
                        <div className={`absolute top-0 right-0 bottom-0 w-4/5 bg-[#6836ffee] rounded-sm py-8 px-12 flex flex-col ${!userProfile ? 'justify-center' : 'justify-center' } overflow-hidden gap-8`}>
                            {userProfile && 
                                <Link to="/profile" className="mb-8">
                                    <img 
                                        src={memoized.profilePictureSrc}  
                                        alt="Profile Picture" 
                                        className="w-16 h-16 object-cover object-center rounded-full mx-auto"
                                        onError={(e) => {
                                        e.target.src = '/global/no-profile.jpg';
                                        }}
                                    />
                                    {userData && userData.name &&
                                    
                                    <p className="text-sm leading-snug font-semibold text-center text-white mt-8">{userData.name}</p>
                                    }
                                    <p className="text-2xs leading-snug text-center text-white mt-2">{userProfile.attributes.email}</p>
                                </Link>

                            }
                            <motion.div className="flex flex-row w-[calc(200%+48px)] gap-x-12"
                                animate={{x: profleMobileShown ? "calc(-50% - 24px)" : 0}}
                                transition={{ease: 'easeOut', duration: 0.4}}
                            >
                                <div className="flex flex-col gap-8 w-full">
                                    <NavItemMobile link="/">Home</NavItemMobile>
                                    <NavItemMobile link="/schedules">Schedules</NavItemMobile>
                                    <NavItemMobile link="/courses">Courses</NavItemMobile>
                                    <NavItemMobile link="/contact-us">Contact Us</NavItemMobile>
                                    
                                    {userProfile ? 
                                        <>
                                            <NavItemMobile link="/my-courses">My Courses</NavItemMobile>
                                            <button className="flex flex-row justify-between text-white items-center" onClick={(e) => {
                                                e.stopPropagation()
                                                setProfleMobileShown(true)}
                                            }>
                                                <p className="w-full text-left text-2xl">
                                                    My Profile
                                                </p>
                                                <img src="/icons/chevron-right.svg" />
                                            </button>
                                        </>

                                        :
                                        
                                        <>
                                            <NavItemMobile link={localStorage.getItem('registration_source') ? '/special-registration' : '/register'}>Register</NavItemMobile>
                                            <NavItemMobile link="/login">Login</NavItemMobile>
                                        </>
                                    }
                                </div>
                                <div className="flex flex-col gap-8 w-full">
                                    
                                    <button className="text-white" onClick={(e) => {
                                        e.stopPropagation()
                                        setProfleMobileShown(false)}
                                    }>
                                        <img src="/icons/chevron-left.svg" />
                                    </button>
                                    <NavItemMobile link="/wishlist">Wishlist</NavItemMobile>
                                    <NavItemMobile link="/my-subscriptions">Subscriptions</NavItemMobile>
                                    <NavItemMobile link="/order-history">Order History</NavItemMobile>
                                    
                                    <button className="flex flex-row justify-between text-white items-center text-left text-2xl" onClick={logout}>
                                        Logout
                                    </button>

                                </div>
                            </motion.div>
                        </div>

                    </motion.div>
                </nav>
            </header>
        </>
    )
}

export default Navbar