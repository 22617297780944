/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getHomeSettings = /* GraphQL */ `
  query GetHomeSettings {
    getHomeSettings {
      PK
      SK
      settings
      createdAt
      updatedAt
    }
  }
`;
export const getTradeOfTheWeek = /* GraphQL */ `
  query GetTradeOfTheWeek {
    getTradeOfTheWeek {
      PK
      SK
      baseType
      details
      createdAt
      updatedAt
    }
  }
`;
export const getGoogleToken = /* GraphQL */ `
  query GetGoogleToken($code: String) {
    getGoogleToken(code: $code) {
      token
    }
  }
`;
export const getProductByLink = /* GraphQL */ `
  query GetProductByLink($link: String) {
    getProductByLink(link: $link) {
      PK
      SK
      baseType
      createdAt
      type
      paymentPlans
      link
      name
      image
      preview
      details
      price
      isLive
      owned
      hasPMR
      inWishlist
      tags
      productsInBundle
      difficulty
      instructors
      sections
      GSI1PK
      GSI1SK
      status
      lastEvaluatedKey
      errors
    }
  }
`;
export const getSuggestedProducts = /* GraphQL */ `
  query GetSuggestedProducts($id: String) {
    getSuggestedProducts(id: $id) {
      PK
      SK
      baseType
      createdAt
      type
      paymentPlans
      link
      name
      image
      preview
      details
      price
      isLive
      owned
      hasPMR
      inWishlist
      tags
      productsInBundle
      difficulty
      instructors
      sections
      GSI1PK
      GSI1SK
      status
      lastEvaluatedKey
      errors
    }
  }
`;
export const getOwnedProducts = /* GraphQL */ `
  query GetOwnedProducts($filter: String) {
    getOwnedProducts(filter: $filter) {
      PK
      SK
      baseType
      createdAt
      type
      paymentPlans
      link
      name
      image
      preview
      details
      price
      isLive
      owned
      hasPMR
      inWishlist
      tags
      productsInBundle
      difficulty
      instructors
      sections
      GSI1PK
      GSI1SK
      status
      lastEvaluatedKey
      errors
    }
  }
`;
export const getOwnedProduct = /* GraphQL */ `
  query GetOwnedProduct($link: String) {
    getOwnedProduct(link: $link) {
      PK
      SK
      baseType
      createdAt
      type
      paymentPlans
      link
      name
      image
      preview
      details
      price
      isLive
      owned
      hasPMR
      inWishlist
      tags
      productsInBundle
      difficulty
      instructors
      sections
      GSI1PK
      GSI1SK
      status
      lastEvaluatedKey
      errors
    }
  }
`;
export const getStudentsOfProductByLink = /* GraphQL */ `
  query GetStudentsOfProductByLink($link: String) {
    getStudentsOfProductByLink(link: $link) {
      PK
      SK
      baseType
      createdAt
      updatedAt
      name
      description
      birthdate
      phone_number
      email
      lastEvaluatedKey
      errors
    }
  }
`;
export const getProductOfInstructorByLinkAndType = /* GraphQL */ `
  query GetProductOfInstructorByLinkAndType($link: String, $type: String) {
    getProductOfInstructorByLinkAndType(link: $link, type: $type) {
      PK
      SK
      baseType
      createdAt
      type
      paymentPlans
      link
      name
      image
      preview
      details
      price
      isLive
      owned
      hasPMR
      inWishlist
      tags
      productsInBundle
      difficulty
      instructors
      sections
      GSI1PK
      GSI1SK
      status
      lastEvaluatedKey
      errors
    }
  }
`;
export const getPromoCode = /* GraphQL */ `
  query GetPromoCode($code: String) {
    getPromoCode(code: $code) {
      PK
      SK
      baseType
      code
      price
      products
      GSI1PK
      GSI1SK
      validFrom
      validUntil
      isLimited
      amountRemaining
      createdAt
      updatedAt
      lastEvaluatedKey
      errors
    }
  }
`;
export const getPromoCodeStudents = /* GraphQL */ `
  query GetPromoCodeStudents($code: String) {
    getPromoCodeStudents(code: $code) {
      PK
      SK
      baseType
      createdAt
      updatedAt
      name
      description
      birthdate
      phone_number
      email
      lastEvaluatedKey
      errors
    }
  }
`;
export const getPromoCodeOfInstructor = /* GraphQL */ `
  query GetPromoCodeOfInstructor($code: String) {
    getPromoCodeOfInstructor(code: $code) {
      PK
      SK
      baseType
      code
      price
      products
      GSI1PK
      GSI1SK
      validFrom
      validUntil
      isLimited
      amountRemaining
      createdAt
      updatedAt
      lastEvaluatedKey
      errors
    }
  }
`;
export const getPromoCodesOfInstructor = /* GraphQL */ `
  query GetPromoCodesOfInstructor {
    getPromoCodesOfInstructor {
      PK
      SK
      baseType
      code
      price
      products
      GSI1PK
      GSI1SK
      validFrom
      validUntil
      isLimited
      amountRemaining
      createdAt
      updatedAt
      lastEvaluatedKey
      errors
    }
  }
`;
export const getProductsOfInstructor = /* GraphQL */ `
  query GetProductsOfInstructor {
    getProductsOfInstructor {
      PK
      SK
      baseType
      createdAt
      type
      paymentPlans
      link
      name
      image
      preview
      details
      price
      isLive
      owned
      hasPMR
      inWishlist
      tags
      productsInBundle
      difficulty
      instructors
      sections
      GSI1PK
      GSI1SK
      status
      lastEvaluatedKey
      errors
    }
  }
`;
export const getProductsOfInstructorByType = /* GraphQL */ `
  query GetProductsOfInstructorByType($type: String) {
    getProductsOfInstructorByType(type: $type) {
      PK
      SK
      baseType
      createdAt
      type
      paymentPlans
      link
      name
      image
      preview
      details
      price
      isLive
      owned
      hasPMR
      inWishlist
      tags
      productsInBundle
      difficulty
      instructors
      sections
      GSI1PK
      GSI1SK
      status
      lastEvaluatedKey
      errors
    }
  }
`;
export const getProductMaterials = /* GraphQL */ `
  query GetProductMaterials($link: String) {
    getProductMaterials(link: $link) {
      PK
      SK
      baseType
      name
      size
      type
      createdAt
      updatedAt
      errors
    }
  }
`;
export const getInstructorProductStream = /* GraphQL */ `
  query GetInstructorProductStream($link: String) {
    getInstructorProductStream(link: $link) {
      PK
      SK
      baseType
      ingestEndpoint
      streamKey
    }
  }
`;
export const getProductVideos = /* GraphQL */ `
  query GetProductVideos($link: String) {
    getProductVideos(link: $link) {
      PK
      SK
      baseType
      link
      name
      createdAt
      updatedAt
      errors
    }
  }
`;
export const getProductVideo = /* GraphQL */ `
  query GetProductVideo($link: String, $code: String) {
    getProductVideo(link: $link, code: $code) {
      PK
      SK
      baseType
      link
      name
      createdAt
      updatedAt
      errors
    }
  }
`;
export const getSectionsByProductAndInstructor = /* GraphQL */ `
  query GetSectionsByProductAndInstructor($link: String) {
    getSectionsByProductAndInstructor(link: $link) {
      PK
      SK
      baseType
      details
      createdAt
      updatedAt
      errors
    }
  }
`;
export const getProductStream = /* GraphQL */ `
  query GetProductStream($link: String) {
    getProductStream(link: $link) {
      playbackUrl
      token
      errors
    }
  }
`;
export const getProductChatToken = /* GraphQL */ `
  query GetProductChatToken($link: String) {
    getProductChatToken(link: $link) {
      token
      errors
    }
  }
`;
export const getInstructorProductChatToken = /* GraphQL */ `
  query GetInstructorProductChatToken($link: String) {
    getInstructorProductChatToken(link: $link) {
      token
      errors
    }
  }
`;
export const getProductSchedule = /* GraphQL */ `
  query GetProductSchedule($link: String) {
    getProductSchedule(link: $link) {
      PK
      SK
      baseType
      details
      date
      start
      end
      link
      name
      type
      description
      GSI1PK
      GSI1SK
      instructors
      lastEvaluatedKey
      errors
    }
  }
`;
export const getScheduleOfMonth = /* GraphQL */ `
  query GetScheduleOfMonth($date: String) {
    getScheduleOfMonth(date: $date) {
      PK
      SK
      baseType
      details
      date
      start
      end
      link
      name
      type
      description
      GSI1PK
      GSI1SK
      instructors
      lastEvaluatedKey
      errors
    }
  }
`;
export const queryProducts = /* GraphQL */ `
  query QueryProducts($params: String) {
    queryProducts(params: $params) {
      total
      products
      errors
    }
  }
`;
export const getProductReviews = /* GraphQL */ `
  query GetProductReviews($link: String) {
    getProductReviews(link: $link) {
      total
      average
      reviews
      ratings
    }
  }
`;
export const getPaymentIntent = /* GraphQL */ `
  query GetPaymentIntent($link: String, $promoCode: String) {
    getPaymentIntent(link: $link, promoCode: $promoCode) {
      clientSecret
      errors
    }
  }
`;
export const getSubscriptionIntent = /* GraphQL */ `
  query GetSubscriptionIntent(
    $link: String
    $id: String
    $packageId: String
    $promoCode: String
  ) {
    getSubscriptionIntent(
      link: $link
      id: $id
      packageId: $packageId
      promoCode: $promoCode
    ) {
      clientSecret
      errors
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile {
    getUserProfile {
      PK
      SK
      baseType
      createdAt
      updatedAt
      name
      description
      birthdate
      phone_number
      email
      lastEvaluatedKey
      errors
    }
  }
`;
export const getUserOrder = /* GraphQL */ `
  query GetUserOrder($id: String) {
    getUserOrder(id: $id) {
      PK
      SK
      baseType
      id
      name
      status
      details
      GSI1PK
      GSI1SK
      createdAt
      updatedAt
      lastEvaluatedKey
      errors
    }
  }
`;
export const getUserOrders = /* GraphQL */ `
  query GetUserOrders {
    getUserOrders {
      PK
      SK
      baseType
      id
      name
      status
      details
      GSI1PK
      GSI1SK
      createdAt
      updatedAt
      lastEvaluatedKey
      errors
    }
  }
`;
export const getUserSubscriptions = /* GraphQL */ `
  query GetUserSubscriptions {
    getUserSubscriptions {
      PK
      SK
      baseType
      name
      details
      GSI1PK
      GSI1SK
      createdAt
      updatedAt
    }
  }
`;
export const getUserSubscription = /* GraphQL */ `
  query GetUserSubscription($id: String) {
    getUserSubscription(id: $id) {
      PK
      SK
      baseType
      name
      details
      GSI1PK
      GSI1SK
      createdAt
      updatedAt
    }
  }
`;
export const getOwnProductReview = /* GraphQL */ `
  query GetOwnProductReview($link: String) {
    getOwnProductReview(link: $link) {
      PK
      SK
      baseType
      link
      rating
      review
      censored
      GSI1PK
      GSI1SK
      createdAt
      updatedAt
      errors
    }
  }
`;
export const getProductPreMarketRoutines = /* GraphQL */ `
  query GetProductPreMarketRoutines($link: String) {
    getProductPreMarketRoutines(link: $link) {
      PK
      SK
      baseType
      date
      indexFutures
      preMarketTips
      whatsInTheNews
      economicReports
      images
      GSI1PK
      GSI1SK
      createdAt
      updatedAt
      lastEvaluatedKey
      errors
    }
  }
`;
export const getWishlist = /* GraphQL */ `
  query GetWishlist {
    getWishlist {
      PK
      SK
      baseType
      link
      name
      image
      details
      price
      status
      instructors
      lastEvaluatedKey
      errors
    }
  }
`;
export const getInstructorDashboard = /* GraphQL */ `
  query GetInstructorDashboard {
    getInstructorDashboard {
      details
      errors
    }
  }
`;
export const getProductOrdersTTM = /* GraphQL */ `
  query GetProductOrdersTTM {
    getProductOrdersTTM {
      details
      errors
    }
  }
`;
export const getStudent = /* GraphQL */ `
  query GetStudent($id: String) {
    getStudent(id: $id) {
      PK
      SK
      baseType
      name
      description
      birthdate
      phone_number
      email
      lastEvaluatedKey
      errors
    }
  }
`;
export const getStudents = /* GraphQL */ `
  query GetStudents {
    getStudents {
      Username
      Attributes
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      lastEvaluatedKey
      errors
    }
  }
`;
export const getInstructor = /* GraphQL */ `
  query GetInstructor($id: String) {
    getInstructor(id: $id) {
      PK
      SK
      baseType
      name
      description
      birthdate
      phone_number
      email
      lastEvaluatedKey
      errors
    }
  }
`;
export const getInstructors = /* GraphQL */ `
  query GetInstructors {
    getInstructors {
      PK
      SK
      baseType
      name
      description
      birthdate
      phone_number
      email
      lastEvaluatedKey
      errors
    }
  }
`;
export const getProductsByInstructorByType = /* GraphQL */ `
  query GetProductsByInstructorByType($id: String, $type: String) {
    getProductsByInstructorByType(id: $id, type: $type) {
      PK
      SK
      baseType
      createdAt
      type
      paymentPlans
      link
      name
      image
      preview
      details
      price
      isLive
      owned
      hasPMR
      inWishlist
      tags
      productsInBundle
      difficulty
      instructors
      sections
      GSI1PK
      GSI1SK
      status
      lastEvaluatedKey
      errors
    }
  }
`;
export const getProductByInstructorByLink = /* GraphQL */ `
  query GetProductByInstructorByLink($id: String, $link: String) {
    getProductByInstructorByLink(id: $id, link: $link) {
      PK
      SK
      baseType
      createdAt
      type
      paymentPlans
      link
      name
      image
      preview
      details
      price
      isLive
      owned
      hasPMR
      inWishlist
      tags
      productsInBundle
      difficulty
      instructors
      sections
      GSI1PK
      GSI1SK
      status
      lastEvaluatedKey
      errors
    }
  }
`;
export const getProductReviewsAdmin = /* GraphQL */ `
  query GetProductReviewsAdmin($link: String) {
    getProductReviewsAdmin(link: $link) {
      PK
      SK
      baseType
      link
      rating
      review
      GSI1PK
      GSI1SK
      email
      name
      censored
      createdAt
      updatedAt
      errors
    }
  }
`;
export const getInstructorCommission = /* GraphQL */ `
  query GetInstructorCommission($id: String) {
    getInstructorCommission(id: $id) {
      PK
      SK
      baseType
      details
      lastEvaluatedKey
      errors
    }
  }
`;
