import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// import awsConfig from './aws-exports';
import { Amplify } from 'aws-amplify';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const root = ReactDOM.createRoot(document.getElementById('root'));

var config = {
  Auth: {
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      oauth: {
          domain: 'themarketinstitution.auth.us-east-1.amazoncognito.com',
          scope: [
              "phone",
              "email",
              "openid",
              "profile",
              "aws.cognito.signin.user.admin"
          ], // Add required Google scopes here
          redirectSignIn: 'https://www.themarketinstitution.com/', // The URL to which users are redirected after signing in with Google
          redirectSignOut: 'https://www.themarketinstitution.com/', // The URL to which users are redirected after signing out
          responseType: 'code', // Set the response type to 'code' to enable Google federation
          federationTarget: 'COGNITO_USER_POOLS'
      },
  },
  Storage: {
      AWSS3: {
          bucket: process.env.REACT_APP_BUCKET,
          region: process.env.REACT_APP_REGION
      }
  },
  "aws_cognito_social_providers": [
      "GOOGLE"
  ],
  "aws_appsync_graphqlEndpoint": process.env.REACT_APP_GRAPHQL_ENDPOINT,
  "aws_appsync_region": process.env.REACT_APP_REGION,
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": process.env.REACT_APP_GRAPHQL_API_KEY
}

var stripeKey = process.env.REACT_APP_STRIPE_KEY

Amplify.configure(config);
const stripePromise = loadStripe(stripeKey)

root.render(
  <BrowserRouter>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
